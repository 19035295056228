<template>
  <div>
    <v-dialog
      v-for="(dialog, index) in stack"
      :key="index"
      :model-value="true"
      :max-width="dialog.fullscreen ? undefined : (dialog.maxWidth || 800)"
      :persistent="Boolean(dialog.persistent)"
      :fullscreen="Boolean(dialog.fullscreen)"
      :content-class="dialog.fullHeight ? 'the-dialog--full-height' : ''"
      :scrollable="dialog.scrollable !== false"
      @update:model-value="$event ? undefined : closeDialog()"
    >
      <component
        :is="dialog.component"
        v-test-id="`dialog-component-${index}`"
        v-bind="{
          ...(dialog.attrs || {}),
          ...(dialog.on || {}),
        }"
      />
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { kebabCase } from 'lodash';
import { mapState, mapMutations } from '~/assets/javascript/modules/vuex';
import DialogConfirmAction from '~/components/dialogs/dialog-confirm-action';
import DialogLoading from '~/components/dialogs/dialog-loading';

const asyncComponent = (name: string) => {
  const componentFolderName = kebabCase(name);

  return defineAsyncComponent({
    // Keep extension to avoid build test files
    loader: () => import(`~/components/dialogs/${componentFolderName}/index.vue`),
    loadingComponent: DialogLoading,
  });
};

const generateAsyncComponents = (names: string[]) => names.reduce((accumulator: Record<string, any>, name: string) => {
  accumulator[name] = asyncComponent(name);
  return accumulator;
}, {});

export const availableAsyncDialogs = [
  'DialogAddRowInViewWithFiltersWarning',
  'DialogConfirmPopupIos',
  'DialogCreateLinkFieldInfoComponent',
  'DialogCreateOrEditCategory',
  'DialogCreateOrEditField',
  'DialogCreateOrEditGlobalParameter',
  'DialogCreateOrEditInfoComponent',
  'DialogCreateOrEditToken',
  'DialogCreateSymmetricLink',
  'DialogCreateSyncFormSubmitWorkflow',
  'DialogDependenciesList',
  'DialogEditCategories',
  'DialogEditChecklistItems',
  'DialogEditMarkdownContent',
  'DialogEditSelectedField',
  'DialogEditViewFields',
  'DialogEditViewFilters',
  'DialogEditViewPermissions',
  'DialogEditViewRecordTemplate',
  'DialogEditViewShareOptions',
  'DialogEditViewSortBy',
  'DialogExpandHistory',
  'DialogFeedback',
  'DialogImportSheetSchema',
  'DialogIntegrationMatches',
  'DialogManageViewWorkflows',
  'DialogNewGroup',
  'DialogNewSheet',
  'DialogNewView',
  'DialogNewWorkflow',
  'DialogPlansPricing',
  'DialogRecord',
  'DialogRename',
  'DialogRuntimeOverride',
  'DialogSchemaItemTextInput',
  'DialogSetSchemaFieldOptions',
  'DialogUploadFile',
  'DialogUserManagement',
  'DialogWorkflowResource',
] as string[];

export default defineComponent({
  name: 'TheDialog',
  components: {
    DialogConfirmAction,
    DialogLoading,
    ...generateAsyncComponents(availableAsyncDialogs),
  },
  setup() {
    return {
      ...mapMutations('dialog', ['closeDialog']),
      ...mapState('dialog', ['stack']),
    };
  },
});
</script>

<style lang="scss">
.the-dialog--full-height {
  min-height: 90%;
}
</style>
